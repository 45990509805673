body,
html {
  font-family: Ubuntu, Helvetica, Arial, Lucida, sans-serif;
  line-height: 23px;
  background-color: #f4efec; 
}

fieldset {
    border: 0;
}





.fieldLabel {
    display:block;
    padding-top: 25px;
    padding-bottom: 5px; 
    font-weight: bolder;
}


.red {
    color: red;
}

.fieldError {
    color: red;
    display: block;
}

.header {
    background-color: #adafb2;
    height: 90px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.logo {
    height: 60px;
    margin: 15px 28px;
}

.gutter {
    /* background-color: #f4efec; */
    width: 25px;
}



.body {
    width: 100%;
    margin: 0px 15px 20px 20px;
    background-color: white;
    padding: 20px;
}

.mobileBody {
    width: 100%;
    margin: 0px;
    background-color: white;
    padding: 20px 0px 0px 0px;
}

.rowDisplay {
    display:flex; 
    flex-direction:row;
}



.title {
    font-weight: 300;
    margin-top: 0;
    font-size: 30px;
    padding: 5px;
}

.subtitle {
    font-weight: 400;
    margin-top: 20px;
    font-size: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.contents {
    margin: 15px;
    color: #5B5B5B;
}

.mobileContents {
    color: #5B5B5B;
}

.textField {
    height: 35px;
    border-radius: 4px;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    padding-left: 10px;
    font-size: 18px;
}

.selectField {
    font-size: 18px;
}

.radioField {
    margin-top: 10px;
}

.third {
    width: 33%;
}

.seventyFive {
    width: 75%;
}

.width90 {
    width: 90%;
}


.width100 {
    width: 100%;
}

button {
    background-color: #263248;
    color: white;
    padding: 7px 11px;
    margin-top: 25px;
    margin-right: 16px;
}


button:hover {
    background-color: #4b6594;
}

.buttonAsLink {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    /* font-family: arial, sans-serif; */
    /*input has OS specific font-family*/
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 16px;
}

.dislosureText {
    width: 100%;
    margin: 20px 0px 20px 0px;
    background-color: white;
}